<template>
  <div class="hello">
    <b-card-group :key="index" v-for="(group, index) in items" columns class="mb-5">
      <b-card :key="item.Id" v-for="item in group" :title="item.title" :sub-title="item.price + ' RWC'" img-height="300" img-width="300" :img-src="item.imageUrl" img-alt="Image" img-top>
        <b-card-text>
          {{item.description}}
        </b-card-text>

        <b-button :id="item.code" href="#" variant="primary" @click="createLink($event)">Купить</b-button>
      </b-card>
    </b-card-group>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  name: 'HelloWorld',
  data () {
    return  { 
      items: []
    }
  },
  mounted: function() {
    axios
      .get('https://d5de8o2fsoor2svd67uj.apigw.yandexcloud.net/rowi-coins-store-nodejs')
      .then(response => {
        this.items = response.data.reduce((resultArray, item, index) => { 
          const chunkIndex = Math.floor(index/3)

          if(!resultArray[chunkIndex]) {
            resultArray[chunkIndex] = []
          }

          resultArray[chunkIndex].push(item)

          return resultArray
        }, [])
      })
  },
  methods: {
    createLink: function (event) {
      var id = String(event.target.id).padStart(5, '0');

      var tempInput = document.createElement('input');
      tempInput.value = "/buy " + id;
      document.body.appendChild(tempInput);
      tempInput.select();
      document.execCommand('copy');
      document.body.removeChild(tempInput);

      this.$bvModal.msgBoxOk('Отправьте ссылку боту @rowi_coins_bot для завершения покупки', {
          title: 'Ссылка скопирована',
          centered: true
        });
    }
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@import "/../assets/scss/vendors/bootstrap-vue/index";
</style>
