<template>
  <div id="app">
    <b-container>
    <b-navbar variant="faded" type="light">
      <b-navbar-brand tag="h1" class="my-2">RowiCoins Store</b-navbar-brand>
    </b-navbar>
    <HelloWorld msg="Welcome to Your Vue.js App"/>
    </b-container>
  </div>
</template>

<script>
import HelloWorld from './components/HelloWorld.vue'

export default {
  name: 'App',
  components: {
    HelloWorld
  }
}
</script>

<style lang="scss">
@import "~@/assets/scss/vendors/bootstrap-vue/index";
body {
  box-sizing: border-box;
}
</style>
